<template>
  <!-- eslint-disable  max-len -->
  <div>
    <tab-body-first-line
      :buttons="buttons"
      :title="$t('super_admin_js.shops.details')"
    />
    <sa-form
      :fields="conditionalFormFields"
      :object="shop"
    />
    <div v-if="canExcludeFromCashRecovery && !excludedFromCashRecovery" class="cash-recovery-wrapper">
      <div class="field-label is-normal">
        <label class="label sk-label">{{ $t('super_admin_js.actions.shop.recovery_exit') }}</label>
      </div>
      <div class="field-body">
        <a class="button is-danger" @click="excludeFromCashRecovery()">
          {{ $t('super_admin_js.actions.shop.exit') }}
        </a>
      </div>
    </div>
    <div v-if="canUpdateFeaturesStates" class="pack-offer">
      <label class="pack-offer-label">{{ $t('super_admin_js.shops.pack_offer') }}</label>
      <select v-model="selectedPackOffer" class="pack-offer-body">
        <option
          v-for="(packOfferName, index) in organisation.packOfferNames"
          :key="index"
          :value="packOfferName.id"
        >
          {{ packOfferName.name }}
        </option>
      </select>
    </div>
    <br>
    <div class="field">
      <div class="field-label is-normal" />
      <div class="field-body">
        <a class="button is-primary" @click="submitForm()">
          {{ $t('super_admin_js.actions.shop.validate') }}
        </a>
      </div>
    </div>
    <import-users-modal
      :visible.sync="importUsersModalVisible"
      :shop="shop"
    />
    <badging-modal
      :visible.sync="badgingModalVisible"
      :title="badgingModalTitle"
      :setting-to-true="settingBadgingToTrue"
      :params="badgingModalParams"
      :callback="handleBadgingActivated"
    >
      <b>{{ shop.name }}</b>
    </badging-modal>
    <mobile-punch-clock-modal
      :visible.sync="mobilePunchClockModalVisible"
      :title="mobilePunchClockModalTitle"
      :setting-to-true="settingMobilePunchClockToTrue"
      :params="mobilePunchClockModalParams"
      :callback="handleMobilePunchClockActivated"
    >
      <b>{{ shop.name }}</b>
    </mobile-punch-clock-modal>
    <automatic-planning-modal
      :visible.sync="automaticPlanningModalVisible"
      :title="automaticPlanningModalTitle"
      :setting-to-true="settingAutomaticPlanningToTrue"
      :params="automaticPlanningModalParams"
      :callback="handleAutomaticPlanningActivated"
    >
      <b>{{ shop.name }}</b>
    </automatic-planning-modal>
    <new-integration-modal
      :title="newIntegrationModalTitle"
      :visible.sync="newIntegrationModalVisible"
      :params="integrationModalParams"
      action="create"
    >
      <b>{{ shop.name }}</b>
    </new-integration-modal>
    <new-integration-modal
      v-if="editIntegrationModalVisible"
      :title="updateIntegrationModalTitle"
      :visible.sync="editIntegrationModalVisible"
      :params="integrationModalParams"
      action="update"
    >
      <b>{{ shop.name }}</b>
    </new-integration-modal>
    <delete-integration-modal
      v-if="deleteIntegrationModalVisible"
      :title="deleteIntegrationModalTitle"
      :visible.sync="deleteIntegrationModalVisible"
      :params="integrationModalParams"
    >
      <b>{{ shop.name }}</b>
    </delete-integration-modal>
    <pay-export-modal
      :visible.sync="payExportModalVisible"
      :title="payExportModalTitle"
      :base-params="badgingModalParams"
      :default-key="defaultKey"
      :exporters="shop.exporters"
      :integrations="shop.global_integrations"
    >
      <b>{{ shop.name }}</b>
    </pay-export-modal>
    <esignature-modal
      :visible.sync="esignatureModalVisible"
      :title="esignatureModalTitle"
      :setting-to-true="settingEsignatureToTrue"
      :params="esignatureModalParams"
      :callback="handleEsignatureActivated"
    >
      <b>{{ shop.name }}</b>
    </esignature-modal>
    <ExtractClockInOutsModal :shop="shop" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import httpClient from '@config/http_client';
import {
  MODAL_SHOW_EVENT,
  skListenersMixin,
} from '@skelloapp/skello-ui';
import { billingAutomationClient } from '~/shared/utils/billing-automation-client';

import { INTEGRATION_EDIT_MODAL_SHOW, INTEGRATION_DELETE_MODAL_SHOW } from '../../constants/integration_auto';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import Form from '../../components/Form';
import BadgingModal from '../../components/BadgingModal';
import MobilePunchClockModal from '../../components/MobilePunchClockModal';
import AutomaticPlanningModal from '../../components/AutomaticPlanningModal';
import NewIntegrationModal from '../../components/integration_auto/NewIntegrationModal';
import DeleteIntegrationModal from '../../components/integration_auto/DeleteIntegrationModal';
import PayExportModal from '../../components/PayExportModal';
import ImportUsersModal from '../../components/ImportUsersModal';
import EsignatureModal from '../../components/EsignatureModal';
import ExtractClockInOutsModal from '../../components/ExtractClockInOutsModal';
import deleteMixin from '../../mixins/deleteMixin';
import impersonateUser, { getLoginErrorKey } from '../../utils/impersonate_user';
/* eslint-disable vue/require-prop-types */
/* eslint-disable vue/require-default-prop */
export default {
  components: {
    TabBodyFirstLine,
    saForm: Form,
    BadgingModal,
    MobilePunchClockModal,
    AutomaticPlanningModal,
    NewIntegrationModal,
    DeleteIntegrationModal,
    PayExportModal,
    ImportUsersModal,
    EsignatureModal,
    ExtractClockInOutsModal,
  },
  mixins: [deleteMixin("l'établissement"), skListenersMixin],
  props: {
    shop: {
      type: Object,
      required: true,
    },
    automaticPlanningDatas: {
      type: Object,
      require: true,
    },
    canEditConventions: {
      type: Boolean,
      required: true,
    },
    canSuspendShop: {
      type: Boolean,
      required: true,
    },
    canExcludeFromCashRecovery: {
      type: Boolean,
      required: true,
    },
    canDeleteShop: {
      type: Boolean,
      required: true,
    },
    canExtractClockInOut: {
      type: Boolean,
      required: true,
    },
    canActivateEsignature: {
      type: Boolean,
      required: true,
    },
    canUpdateFeaturesStates: {
      type: Boolean,
      require: true,
    },
    organisation: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      badgingModalVisible: false,
      mobilePunchClockModalVisible: false,
      automaticPlanningModalVisible: false,
      newIntegrationModalVisible: false,
      editIntegrationModalVisible: false,
      deleteIntegrationModalVisible: false,
      payExportModalVisible: false,
      esignatureModalVisible: false,
      importUsersModalVisible: false,
      noIntegration: true,
      excludedFromCashRecovery: false,
      extractClockInOutsModalVisible: false,
      selectedPackOffer: '',
      formFields: [
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.table.id'),
          key: 'id',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.table.name'),
          key: 'name',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.organisation.details_form.corporate_name'),
          key: 'corporateName',
        },
        {
          type: 'link',
          label: this.$t('super_admin_js.users.table.organisation'),
          key: 'organisation',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.dem_organisation'),
          key: 'corporateNameOrganisation',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.table.bizdev'),
          key: 'referentBizdev',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.owner'),
          key: 'coach',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.active_employees'),
          key: 'nActiveEmployees',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.remaining_planner'),
          key: 'automaticPlanningUsesLeft',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.rewards_number'),
          key: 'automaticPlanningGivenCreditCount',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.table.billing_address'),
          key: 'billingAddress',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.billing_pc'),
          key: 'billingZipcode',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.billing_city'),
          key: 'billingCity',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.billing_email'),
          key: 'billingEmail',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.convention'),
          key: 'conventionName',
          optionsKey: 'availableConventions',
        },
        {
          type: 'switch',
          label: this.$t('super_admin_js.shops.flex_conv'),
          key: 'flexibleConvention',
          disabled: !this.canEditConventions,
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.conv_type'),
          key: 'conventionFlexibleText',
        },
        {
          type: 'textWithEditAndDeleteSvg',
          label: this.$t('super_admin_js.shops.form.pay_export'),
          key: 'integrationName',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.table.clocking_terminal'),
          key: 'badgingActiveText',
        },
        {
          type: 'text',
          label: this.$t('super_admin_js.shops.form.siret'),
          key: 'siret',
        },
        {
          type: 'switch',
          label: this.$t('super_admin_js.shops.form.suspended_shop'),
          key: 'cancelled',
          disabled: !this.canSuspendShop,
          // TODO uncomment when we're ready Raul side
          // disabled: !this.canSuspendShop || this.shop.createdFromSvcBillingAutomation,
        },
        {
          type: 'dateInput',
          label: this.$t('super_admin_js.shops.form.suspention_date'),
          key: 'cancelledAt',
          disabled: !this.canSuspendShop,
          // TODO uncomment when we're ready Raul side
          // disabled: !this.canSuspendShop || this.shop.createdFromSvcBillingAutomation,
        },
      ],
    };
  },
  computed: {
    ...mapState(['devFeatureFlags']),

    conditionalFormFields() {
      if (this.canEditConventions) {
        _.remove(this.formFields, (n) => n.label === this.$t('super_admin_js.shops.conv_type'));
        this.formFields.find((field) => field.label === this.$t('super_admin_js.shops.flex_conv')).disabled = false;
      } else {
        _.remove(this.formFields, (n) => n.label === this.$t('super_admin_js.shops.flex_conv'));
      }

      if (this.shop.maxAutomaticPlanningUses === 0) {
         _.remove(this.formFields, (n) => n.key === 'automaticPlanningUsesLeft');
      }
      this.formFields.find((field) => field.key === 'cancelledAt').shouldShow = () => this.shop.cancelled;
      if (!this.shop.modulation) {
        _.remove(this.formFields, (n) => n.label === this.$t('super_admin_js.shops.new_counter'));
      }
      return this.formFields;
    },
    badgingActiveText() {
      return this.shop.badging ? this.$t('actions.active') : this.$t('actions.not_active');
    },
    settingBadgingToTrue() {
      return !this.shop.badging;
    },
    settingMobilePunchClockToTrue() {
      return !this.shop.canEnableMobilePunchClock;
    },
    settingAutomaticPlanningToTrue() {
      return !this.shop.automatic_planning_active;
    },
    settingEsignatureToTrue() {
      return !this.shop.esignature_active;
    },
    settingPayrollExportToTrue() {
      return !this.shop.payExporter;
    },
    badgingModalParams() {
      return {
        badging: String(this.settingBadgingToTrue),
        type: 'shop',
        shop_id: this.shop.id,
      };
    },
    mobilePunchClockModalParams() {
      return {
        shop_extended_infos: {
          can_enable_mobile_punch_clock: !this.shop.canEnableMobilePunchClock,
        },
        shop_id: this.shop.id,
      };
    },
    automaticPlanningModalParams() {
      return {
        automatic_planning_active: String(this.settingAutomaticPlanningToTrue),
        type: 'shop',
        shop_id: this.shop.id,
      };
    },
    esignatureModalParams() {
      return {
        esignature_active: String(this.settingEsignatureToTrue),
        type: 'shop',
        shop_id: this.shop.id,
      };
    },
    badgingModalTitle() {
      return this.shop.badging ? this.$t('super_admin_js.modals.titles.deactivate_clock', { shop: this.shop.name }) : this.$t('super_admin_js.modals.titles.activate_clock', { shop: this.shop.name });
    },
    mobilePunchClockModalTitle() {
      return this.shop.canEnableMobilePunchClock
        ? this.$t('super_admin_js.modals.titles.deactivate_mobile_punch_clock', { shop: this.shop.name })
        : this.$t('super_admin_js.modals.titles.activate_mobile_punch_clock', { shop: this.shop.name });
    },
    automaticPlanningModalTitle() {
      return this.shop.automatic_planning_active
        ? this.$t('super_admin_js.modals.titles.deactivate_sm', { shop: this.shop.name })
        : this.$t('super_admin_js.modals.titles.activate_sm', { shop: this.shop.name });
    },
    esignatureModalTitle() {
      const key = this.shop.esignature_active ? 'super_admin_js.modals.titles.disable_esignature' : 'super_admin_js.modals.titles.activate_esignature';
      return this.$t(key, { shop: this.shop.name });
    },
    integrationModalParams() {
      return {
        type: 'shop',
        shop_id: this.shop.id,
      };
    },
    buttons() {
      const buttonsList = [
        {
          fontPack: 'fa',
          icon: 'user',
          onClick: this.impersonateFirstAdmin,
          tooltipLabel: this.$t('super_admin_js.shops.buttons.see_as'),
        },
        {
          fontPack: 'fa',
          icon: 'mobile',
          onClick: this.handleMobilePunchClockClick,
          activeState: !this.settingMobilePunchClockToTrue,
          tooltipLabel: this.$t('super_admin_js.shops.buttons.activate_mobile_punch_clock', { activate: this.activateText(this.shop.canEnableMobilePunchClock) }),
          hidden: !this.shop.badging,
        },
        {
          fontPack: 'fa',
          icon: 'magic',
          onClick: this.handleAutomaticPlanningClick,
          activeState: !this.settingAutomaticPlanningToTrue,
          tooltipLabel: this.$t('super_admin_js.shops.buttons.activate_sp', { activate: this.activateText(this.shop.automatic_planning_active) }),
        },
        {
          fontPack: 'fa',
          icon: 'cogs',
          onClick: this.handleNewIntegrationExportClick,
          tooltipLabel: this.$t('super_admin_js.shops.buttons.create_integration'),
        },
        {
          fontPack: 'fa',
          icon: 'usd',
          onClick: this.handlePayExportClick,
          activeState: !this.settingPayrollExportToTrue,
          tooltipLabel: this.$t('super_admin_js.shops.buttons.activate_export'),
        },
        {
          fontPack: 'fa',
          icon: 'users',
          onClick: this.handleImportUsersClick,
          tooltipLabel: this.$t('super_admin_js.shops.buttons.import_users'),
        },
        {
          fontPack: 'fa',
          icon: 'pencil-square',
          onClick: this.handleEsignatureClick,
          activeState: !this.settingEsignatureToTrue,
          tooltipLabel: this.$t('super_admin_js.shops.buttons.activate_esignature', { activate: this.activateText(this.shop.esignature_active) }),
          hidden: !this.canActivateEsignature,
        },
        {
          fontPack: 'fa',
          icon: 'id-badge',
          onClick: this.handleExtractClockInOutClick,
          tooltipLabel: this.$t('super_admin_js.users.extract_clock_in_outs'),
          hidden: !this.shop.badging || !this.canExtractClockInOut,
        },
        {
          fontPack: 'fa',
          icon: 'trash',
          iconColor: '#FF5E5F',
          onClick: this.handleDeleteClick,
          tooltipLabel: this.$t('super_admin_js.modals.delete.button_tooltip', { entity: this.$t('super_admin_js.shops.this_shop') }),
          hidden: !this.canDeleteShop,
        },
      ];

      if (!this.shop.features_states?.new_bundle_system?.activated
      || !this.shop.features_states?.timeclock?.activated) {
        buttonsList.splice(1, 0, {
          fontPack: 'fa',
          icon: 'clock-o',
          onClick: this.handleBadgingClick,
          activeState: !this.settingBadgingToTrue,
          tooltipLabel: this.$t('super_admin_js.shops.buttons.activate_clock', { activate: this.activateText(this.shop.badging) }),
        });
      }

      return buttonsList;
    },
    defaultKey() {
      if (!this.shop) return null;
      return this.shop.payExporterKey;
    },
    deleteModalTitle() {
      return this.$t('super_admin_js.modals.delete.title', { name: this.shop.name });
    },
    deleteMessageWarning() {
      return this.$t('super_admin_js.modals.delete.message', { name: this.shop.name });
    },
    deletePath() {
      return `/super_admin/api/shops/${this.$route.params.id}`;
    },
    payExportModalTitle() {
      return this.$t('super_admin_js.modals.titles.shop_export', { name: this.shop.name });
    },
    newIntegrationModalTitle() {
      return this.$t('super_admin_js.modals.titles.new_integration');
    },
    updateIntegrationModalTitle() {
      return this.$t('super_admin_js.modals.titles.edit_integration');
    },
    deleteIntegrationModalTitle() {
      return this.$t('super_admin_js.modals.titles.deactivate_integration');
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-expressions
    this.listenOnRoot;
    this.$root.$on(INTEGRATION_EDIT_MODAL_SHOW, this.handleUpdatePayExportClick);
    this.$root.$on(INTEGRATION_DELETE_MODAL_SHOW, this.handleDeletePayExportClick);
    this.$on('beforeDestroy', () => {
      this.$root.$off(INTEGRATION_EDIT_MODAL_SHOW);
      this.$root.$off(INTEGRATION_DELETE_MODAL_SHOW);
    });

    this.shop.licenses.forEach((originalType) => {
      const licenseName = this.$t(`super_admin_js.licenses.${originalType}`);

      this.formFields.push({
        type: 'linksList',
        label: licenseName,
        key: originalType,
      });
    });
  },
  methods: {
    activateText(bool) {
      return bool ? this.$t('actions.deactivate') : this.$t('actions.activate');
    },
    removeOffsetFromCancellationDate(cancelledAt) {
      if (!this.shop.cancelled) return null;

      // remove the offset so that date is at midnight UTC instead of midnight user TZ
      return new Date(cancelledAt.getTime() - (cancelledAt.getTimezoneOffset() * 60000));
    },
    impersonateFirstAdmin() {
      const systemAdmin = _.find(this.shop.system_admin, { active: true });
      if (!systemAdmin) return;
      impersonateUser(systemAdmin.id)
        .catch((error) => {
          this.makeAlertToast(this.$t(getLoginErrorKey(error)));
        });
    },
    salesforceDeleteCallback() {
      return billingAutomationClient.requestShopSalesforceDelete(this.shop.id);
    },
    submitForm() {
      const cancellationDate = this.removeOffsetFromCancellationDate(this.shop.cancelledAt);
      httpClient
        .patch(`/super_admin/api/shops/${this.shop.id}`, {
          shop: {
            flexible_convention: this.shop.flexibleConvention,
            cancelled_at: cancellationDate,
            packOfferToUpdate: this.selectedPackOffer,
          },
        })
        .then((response) => {
          const newShop = response.data.shop;
          if (newShop.cancelledAt) {
            newShop.cancelledAt = new Date(newShop.cancelledAt);
          } else {
            // set default value for if the cancelled switch is turned on
            newShop.cancelledAt = new Date();
          }
          this.$emit('update:shop', newShop);
          this.makeSuccessToast(this.$t('super_admin_js.shops.shop_edited'));
        })
        .catch((e) => {
          this.makeAlertToast(this.$t('super_admin_js.shops.shop_edited_error'));
        });
    },
    excludeFromCashRecovery() {
      this.$dialog.confirm({
        title: this.$t('super_admin_js.shops.recovery'),
        message: this.$t('super_admin_js.shops.recovery_confirmation'),
        confirmText: 'Oui',
        cancelText: 'Non',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.unDisputeLastStripeCharge();
        },
      });
    },
    unDisputeLastStripeCharge() {
      httpClient
        .post('/super_admin/api/stripe_charges/undispute_last', {
          undispute: {
            shop_id: this.shop.id,
          },
        })
        .then((response) => {
          this.excludedFromCashRecovery = true;
          this.makeSuccessToast(this.$t('super_admin_js.shops.recovery_out'));
        })
        .catch((e) => {
          this.makeAlertToast(this.$t('errors.error_happened'));
        });
    },
    handleBadgingActivated() {
      this.shop.badging = !this.shop.badging;
      this.shop.badgingActiveText = this.badgingActiveText;
    },
    handleMobilePunchClockActivated() {
      this.shop.canEnableMobilePunchClock = !this.shop.canEnableMobilePunchClock;
      this.shop.badgingActiveText = this.badgingActiveText;
    },
    handleAutomaticPlanningActivated() {
      this.shop.automatic_planning_active = !this.shop.automatic_planning_active;
      this.shop.automaticPlanningActiveText = this.automaticPlanningActiveText;
    },
    handleEsignatureActivated() {
      this.shop.esignature_active = !this.shop.esignature_active;
    },
    handleBadgingClick() {
      this.badgingModalVisible = true;
    },
    handleMobilePunchClockClick() {
      this.mobilePunchClockModalVisible = true;
    },
    handleAutomaticPlanningClick() {
      this.automaticPlanningModalVisible = true;
    },
    handleImportUsersClick() {
      this.importUsersModalVisible = true;
    },
    handleNewIntegrationExportClick() {
      this.newIntegrationModalVisible = true;
    },
    handleUpdatePayExportClick() {
      this.editIntegrationModalVisible = true;
    },
    handleDeletePayExportClick() {
      this.deleteIntegrationModalVisible = true;
    },
    handlePayExportClick() {
      this.payExportModalVisible = true;
    },
    handleEsignatureClick() {
      this.esignatureModalVisible = true;
    },
    handleExtractClockInOutClick(event) {
      this.emitOnRoot(MODAL_SHOW_EVENT, event, 'extract-clock-in-outs-modal-id');
    },
  },
};
</script>

<style lang="scss" scoped>
  .cash-recovery-wrapper {
    display: flex;
  }
  .pack-offer {
    align-items: flex-start;
    display: flex;
    gap: 10px;
    justify-content: left;
    padding-left: 20%;
  }
  .pack-offer-label {
    color: $sk-black;
    font-weight: 700;
    margin-right: 20px;
    text-align: right;
  }
  .pack-offer-body {
    display: flex;
  }
  .pack-offer select {
    border-radius: 4px;
  }
</style>
