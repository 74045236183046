<template>
  <!-- eslint-disable  max-len -->
  <div>
    <header-content v-if="organisation">
      <router-link
        :to="organisationPath"
        class="organisation-link"
      >
        {{ organisation.name }}
      </router-link>
      >
      {{ shop.name }}
    </header-content>
    <spinner v-if="loading" />
    <main-content v-else>
      <tabs>
        <tab :name="$t('super_admin_js.shops.details')">
          <shop-details
            :shop.sync="shop"
            :can-edit-conventions="canEditConventions"
            :can-suspend-shop="canSuspendShop"
            :can-exclude-from-cash-recovery="canExcludeFromCashRecovery"
            :can-activate-esignature="canActivateEsignature"
            :can-delete-shop="canDeleteShop"
            :can-extract-clock-in-out="canExtractClockInOut"
            :can-update-features-states="canUpdateFeaturesStates"
            :organisation="organisation"
          />
        </tab>
        <tab :name="$t('super_admin_js.organisation.tabs.employees')">
          <users-list :shop="shop" />
        </tab>
        <tab :name="$t('super_admin_js.organisation.tabs.invoices')">
          <invoices-list :shop="shop" />
        </tab>
        <tab :name="$t('super_admin_js.organisation.tabs.settings')">
          <shop-settings
            :shop.sync="shop"
            :can-add-automatic-planning-credits="canAddAutomaticPlanningCredits"
            :refresh-shop="fetchData"
          />
        </tab>
        <tab :name="$t('super_admin_js.history')">
          <shop-history
            :shop.sync="shop"
          />
        </tab>
        <tab
          v-if="isEventTabEnabled"
          :name="$t('super_admin_js.events_history.title')"
        >
          <events-history
            :id="eventsShopId"
            type="shop"
          />
        </tab>
      </tabs>
    </main-content>
  </div>
</template>

<script>
import httpClient from '@config/http_client';
import { mapGetters } from 'vuex';

import _ from 'lodash';
import HeaderContent from '../../components/HeaderContent';
import Tabs from '../../components/Tabs';
import Tab from '../../components/Tab';
import MainContent from '../../components/MainContent';
import Spinner from '../../components/Spinner';
import ShopDetails from './ShopDetails';
import UsersList from './UsersList';
import InvoicesList from './InvoicesList';
import ShopSettings from './ShopSettings';
import ShopHistory from './ShopHistory';
import EventsHistory from '../../components/EventsHistory/index.vue';
import { RELOAD_SHOP_DATA } from '../../constants/integration_auto';

export default {
  components: {
    HeaderContent,
    MainContent,
    Tabs,
    Tab,
    ShopDetails,
    UsersList,
    InvoicesList,
    ShopSettings,
    ShopHistory,
    EventsHistory,
    Spinner,
  },
  beforeRouteUpdate(to, from, next) {
    this.shopId = to.params.id;
    this.fetchData();
    next();
  },
  data() {
    return {
      loading: true,
      shop: {},
      organisation: {},
      shopId: this.$route.params.id,
      canAddAutomaticPlanningCredits: false,
      canEditConventions: false,
      canSuspendShop: false,
      canExcludeFromCashRecovery: false,
      canActivateEsignature: false,
      canDeleteShop: false,
      canExtractClockInOut: false,
    };
  },
  computed: {
    ...mapGetters(['isDevFeatureFlagActive']),
    organisationPath() {
      if (!this.organisation) return '';
      return {
        name: 'organisation',
        params: {
          id: this.organisation.id,
        },
      };
    },
    eventsShopId() {
      return parseInt(this.shopId);
    },
    isEventTabEnabled() {
      return this.isDevFeatureFlagActive('FEATUREDEV_SVC_EVENTS_ENABLED');
    },
  },
  created() {
    this.fetchData();
    this.$root.$on(RELOAD_SHOP_DATA, this.fetchData.bind(this));
    this.$root.$on('beforeDestroy', () => {
      this.$root.$off(RELOAD_SHOP_DATA);
    });
  },
  methods: {
    fetchData() {
      this.loading = true;
      httpClient
        .get(`/super_admin/api/shops/${this.shopId}`)
        .then((response) => {
          this.shop = response.data.shop;
          if (this.shop.cancelledAt) {
            this.shop.cancelledAt = new Date(this.shop.cancelledAt);
          } else {
            // set default value for if the cancelled switch is turned on
            this.shop.cancelledAt = new Date();
          }
          this.fetchOrganisation();
          this.canAddAutomaticPlanningCredits = response.data.can_add_automatic_planning_credits;
          this.canEditConventions = response.data.can_edit_conventions;
          this.canSuspendShop = response.data.can_suspend_shop;
          this.canExcludeFromCashRecovery = response.data.can_exclude_from_cash_recovery;
          this.canDeleteShop = response.data.can_delete_shop;
          this.canActivateEsignature = response.data.can_activate_esignature;
          this.canExtractClockInOut = response.data.can_extract_clock_in_out;
          this.canUpdateFeaturesStates = response.data.can_update_features_states;
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.$router.push('/404');
            return;
          }
          this.makeAlertToast(
            this.$t('super_admin_js.errors.shop_error'),
          );
        }).finally(() => {
          this.loading = false;
        });
    },
    fetchOrganisation() {
      httpClient
        .get(`/super_admin/api/organisations/${this.shop.organisationId}`)
        .then((response) => {
          this.organisation = response.data.organisation;
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.$router.push('/404');
            return;
          }
          this.makeAlertToast(
            this.$t('super_admin_js.errors.shop_error'),
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.organisation-link {
  color: inherit;

  &:hover {
    opacity: .8;
  }
}
</style>
