import httpClient from '../config/http_client';

export default function impersonateUser(userId) {
  return httpClient
    .get(`/super_admin/api/users/${userId}/impersonate_callback_url`)
    .then((response) => {
      const callbackUrl = response.data.callback_url;

      // use this cookie when reviewapps are using sandbox url
      // right now set in backend
      // security.setImpersonateCookie(userId);

      window.location = callbackUrl;
    });
}

export const getLoginErrorKey = (error) => {
  const errorType = error.response.status === 403 ? 'user' : 'server';
  const loginError = JSON.parse(error.response.data.error);

  const errorKey = loginError.key ? `errors.${loginError.key}` : `super_admin_js.users.errors.impersonate.${errorType}`;

  return errorKey;
};
