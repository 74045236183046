import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5c27b495&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=5c27b495&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@7.0.0-bridge.0_css-loader@6.11.0_ejs@3.1.10_lodash@4.17.21_must_bawmdeenyun5yx33az7c7jlejy/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c27b495",
  null
  
)

export default component.exports