<template>
  <div>
    <update-contract-modal
      :visible.sync="updateContractModalVisible"
      :organisationId="organisation.id"
    />
    <update-user-data-modal
      :visible.sync="updateUserDataModalVisible"
      :organisationId="organisation.id"
    />
    <update-user-annualization-data-modal
      :visible.sync="updateUserAnnualizationDataModalVisible"
      :organisationId="organisation.id"
    />
    <update-kpis-modal
      :visible.sync="updateKpisModalVisible"
      :organisationId="organisation.id"
    />
    <update-postes-modal
      :visible.sync="updatePostesModalVisible"
      :organisationId="organisation.id"
    />
    <update-plcs-modal
      :visible.sync="updatePlcsModalVisible"
      :organisationId="organisation.id"
      :date="plcsUpdateDate"
    />
    <manual-relaunch-modal
      :visible.sync="manualRelaunchModalVisible"
      :job="manualRelaunchModalJob"
      :organisationId="organisation.id"
    />

    <div>
      <tab-body-first-line :title="$t('super_admin_js.organisation.tabs.integration_tasks')" />
      <SkTable
        class="integrations-jobs__table"
        :columns="headers"
        filled-arrow
        @sort="handleSort"
      >
        <tr
          v-for="job in visibleJobs"
          :key="job.key"
          class="table__row"
        >
          <td class="table__cell">
            <div class="table__cell-content text-truncate">
              {{ job.label }}
            </div>
          </td>

          <td
            v-if="job.formattedDate"
            class="table__cell"
          >
            <SkDatePicker
              v-model="job.formattedDate"
              :defaultValue="toDate(job.formattedDate)"
              :clearable="false"
              no-icon
            />
          </td>
          <td v-else class="table__cell" />

          <td
            v-if="job.key.startsWith('hris_')"
            v-tooltip="buildMainContactsTooltip(job.submit.params)"
            class="table__cell"
          >
            <spinner
              v-if="isFetchingHrisData"
            />
            <SkSelectV2
              v-else
              :value="mainContacts[job.submit.params]"
              :options="administratorOptions"
              :label="$t('super_admin_js.integration_tasks.main_contact.label')"
              :disabled="job.isLoading"
              multi
              @input="onSelectMainContact($event, job.submit.params)"
            >
              <template #selected-option>
                <span>
                  {{ displayAdministrators(job.submit.params) }}
                </span>
              </template>
              <template #empty-option>
                {{ $t('super_admin_js.ui.search_bar.no_result') }}
              </template>
            </SkSelectV2>
          </td>
          <td v-else-if="organisation.hrisIntegration" class="table__cell" />

          <td class="table__cell">
            <SkIconButton
              v-tooltip.top="job.submit.tooltip"
              :class="job.submit.class"
              :icon="job.submit.icon"
              :keep-background="true"
              fill="outlined"
              :spinner="job.isLoading"
              @click="job.submit.event(job.submit?.params)"
            />
          </td>
        </tr>
      </SkTable>
    </div>
  </div>
</template>

<script>
import {
  SkTable,
  SkIconButton,
  SkDatePicker,
  SkSelectV2,
 } from '@skelloapp/skello-ui';
import moment from 'moment';
import httpClient from '@config/http_client';
import TabBodyFirstLine from '../../components/TabBodyFirstLine';
import UpdateContractModal from '../../components/UpdateContractModal';
import UpdateUserAnnualizationDataModal from '../../components/UpdateUserAnnualizationDataModal';
import UpdateUserDataModal from '../../components/UpdateUserDataModal';
import UpdateKpisModal from '../../components/UpdateKpisModal';
import UpdatePlcsModal from '../../components/UpdatePlcsModal';
import UpdatePostesModal from '../../components/UpdatePostesModal';
import ManualRelaunchModal from '../../components/ManualRelaunchModal';
import Spinner from '../../components/Spinner';

export default {
  components: {
    TabBodyFirstLine,
    SkTable,
    SkIconButton,
    SkDatePicker,
    SkSelectV2,
    Spinner,
    UpdateContractModal,
    UpdateUserDataModal,
    UpdateUserAnnualizationDataModal,
    UpdateKpisModal,
    UpdatePlcsModal,
    UpdatePostesModal,
    ManualRelaunchModal,
  },
  props: {
    organisation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      updateContractModalVisible: false,
      updateUserDataModalVisible: false,
      updateUserAnnualizationDataModalVisible: false,
      updateKpisModalVisible: false,
      updatePlcsModalVisible: false,
      updatePostesModalVisible: false,
      manualRelaunchModalVisible: false,
      manualRelaunchModalJob: {},
      jobs: [
        // We will inject HRIS integration here with fetchHrisConfig
        // Pay Identification Numbers update
        {
          label: this.$t('super_admin_js.integration_tasks.update_contracts_job_label'),
          key: 'contracts_update',
          isLoading: false,
          visible: true,
          submit: {
            tooltip: this.$t('super_admin_js.integration_tasks.update_matricule_button'),
            class: 'sk-round-button-update',
            icon: 'TeamIcon',
            event: this.handleContractsUpdate,
          },
        },
        // User Data update
        {
          label: this.$t('super_admin_js.integration_tasks.update_user_data_job_label'),
          key: 'user_data_update',
          isLoading: false,
          visible: true,
          submit: {
            tooltip: this.$t('super_admin_js.integration_tasks.update_user_data_button'),
            class: 'sk-round-button-update',
            icon: 'PersonIcon',
            event: this.handleUserDataUpdate,
          },
        },
        // User Annualization Data update
        {
          label: this.$t('super_admin_js.integration_tasks.update_user_annualization_data_job_label'),
          key: 'user_annualization_data_update',
          isLoading: false,
          visible: true,
          submit: {
            tooltip: this.$t('super_admin_js.integration_tasks.update_user_annualization_data_button'),
            class: 'sk-round-button-update',
            icon: 'CalendarClockIcon',
            event: this.handleUserAnnualizationDataUpdate,
          },
        },
        // KPIs update
        {
          label: this.$t('super_admin_js.integration_tasks.update_kpis_label'),
          key: 'kpis_update',
          isLoading: false,
          visible: true,
          submit: {
            tooltip: this.$t('super_admin_js.integration_tasks.update_kpis_label'),
            class: 'sk-round-button-update',
            icon: 'AnalyticsIcon',
            event: this.handleKPIsUpdate,
          },
        },
        // PLCs update
        {
          label: this.$t('super_admin_js.integration_tasks.update_plcs_label'),
          formattedDate: moment().utc().format('YYYY-MM-DD'),
          key: 'plcs_update',
          isLoading: false,
          visible: true,
          submit: {
            tooltip: this.$t('super_admin_js.integration_tasks.update_plcs_label'),
            class: 'sk-round-button-update',
            icon: 'ParasolIcon',
            event: this.handlePLCsUpdate,
          },
        },
        // Poste update
        {
          label: this.$t('super_admin_js.integration_tasks.update_poste_label'),
          key: 'poste_update',
          isLoading: false,
          visible: true,
          submit: {
            tooltip: this.$t('super_admin_js.integration_tasks.update_poste_label'),
            class: 'sk-round-button-update',
            icon: 'TagIcon',
            event: this.handlePostesUpdate,
          },
        },
      ],
      automaticJobs: [],
      headers: [
        {
          name: 'label',
          title: this.$t('super_admin_js.integration_tasks.title_label'),
          sort: true,
          defaultSort: 'asc',
          class: 'table__header-cell job-name__header',
        },
        {
          name: 'execution_date',
          title: this.$t('super_admin_js.integration_tasks.date_label'),
          class: 'table__header-cell date__header',
        },
        {
          name: 'action',
          title: this.$t('super_admin_js.integration_tasks.action_label'),
          class: 'table__header-cell',
        },
      ],
      isFetchingHrisData: false,
      organisationContacts: {},
      organisationAdministrators: {},
      mainContacts: {},
    };
  },
  computed: {
    plcsUpdateDate() {
      return this.jobs.find((job) => job.key === 'plcs_update').formattedDate;
    },
    visibleJobs() {
      return [...this.jobs, ...this.automaticJobs].filter((job) => job.visible);
    },
    administratorOptions() {
      return Object.values(this.organisationAdministrators);
    },
  },
  mounted() {
    this.fetchAutomaticJobs();
    if (this.organisation.hrisIntegration) {
      this.fetchOrganisationContacts();
      this.fetchHrisConfig();
      this.upsertMainContactHeaders();
      this.fetchOrganisationAdministrators();
    }
  },
  methods: {
    fetchHrisConfig() {
      httpClient
        .get(`/super_admin/api/integrations/hris_integration_keys/${this.organisation.id}`)
        .then((response) => {
          response.data.data.forEach((integrationKey) => {
            const formattedIntegration = {
              label: this.$t('super_admin_js.integration_tasks.hris_label_for', { integrationKey }),
              formattedDate: moment().utc().format('YYYY-MM-DD'),
              key: `hris_${integrationKey}`,
              isLoading: false,
              visible: this.organisation.hrisIntegration,
              submit: {
                tooltip: this.$t('super_admin_js.integration_tasks.start_button'),
                class: 'sk-round-button-start',
                icon: 'CircleBackArrowIcon',
                event: this.handleHris,
                params: integrationKey,
              },
            };
            this.jobs.splice(0, 0, formattedIntegration);
          });
        })
        .catch((error) => {
          this.makeAlertToast(
            this.$t('super_admin_js.integration_tasks.hris_integration_keys_fetch_fail'),
          );
        });
    },
    fetchAutomaticJobs() {
      httpClient
        .get('/super_admin/api/integrations/automatic_jobs')
        .then((response) => {
          this.automaticJobs = this.extractAutomaticJobs(response.data.data);
        })
        .catch((error) => {
          this.makeAlertToast(
            this.$t('super_admin_js.integration_tasks.automatic_job_fetch_fail'),
          );
        });
    },
    extractAutomaticJobs(jobsResponse) {
      const organisationJobs = jobsResponse[this.organisation.id];

      if (!organisationJobs) return [];

      return organisationJobs.map((job) => ({
        label: this.$t('super_admin_js.integration_tasks.manual_launch.label', { job_name: job.display_name }),
        key: `manual_launch_${job.name}`,
        isLoading: false,
        visible: true,
        submit: {
          tooltip: this.$t('super_admin_js.integration_tasks.manual_launch.tooltip'),
          class: 'sk-round-button-update',
          icon: 'DownloadArrowIcon',
          event: () => { this.openManualRelaunchModal(job); },
        },
      }));
    },
    openManualRelaunchModal(job) {
      this.manualRelaunchModalJob = job;
      this.manualRelaunchModalVisible = true;
    },
    toDate(formattedDate) {
      return moment(formattedDate).utc().toDate();
    },
    handleHris(integrationKey) {
      const job = this.jobs.find((jobObj) => jobObj.key === `hris_${integrationKey}`);

      job.isLoading = true;

      const params = {
        organisation_id: this.organisation.id,
        date: job.formattedDate,
        key: 'hris',
        integration_id: integrationKey,
      };

      httpClient
        .post('/super_admin/api/integrations/integrations/run_integration_job', params)
        .then(() => {
          this.makeToast(
            'is-success',
            this.$t('super_admin_js.integration_tasks.launching'),
          );
        })
        .catch(() => {
          this.makeAlertToast(
            this.$t('super_admin_js.integration_tasks.error_launching'),
          );
        })
        .finally(() => {
          job.isLoading = false;
        });
    },
    handleContractsUpdate() {
      this.updateContractModalVisible = true;
    },
    handleUserDataUpdate() {
      this.updateUserDataModalVisible = true;
    },
    handleUserAnnualizationDataUpdate() {
      this.updateUserAnnualizationDataModalVisible = true;
    },
    handleKPIsUpdate() {
      this.updateKpisModalVisible = true;
    },
    handlePLCsUpdate() {
      this.updatePlcsModalVisible = true;
    },
    handlePostesUpdate() {
      this.updatePostesModalVisible = true;
    },
    handleSort(_event, column, direction) {
      this.jobs.sort((firstItem, secondItem) => {
        const first = firstItem[column].toLowerCase();
        const second = secondItem[column].toLowerCase();

        return direction === 'asc' ? first.localeCompare(second) : second.localeCompare(first);
      });
    },
    fetchOrganisationContacts() {
      this.isFetchingHrisData = true;

      httpClient
      .get(`/super_admin/api/organisations/${this.organisation.id}/contacts`)
      .then((response) => {
          this.organisationContacts = response.data.contacts;
          this.initializeMainContacts();
        })
        .catch((error) => {
          this.makeAlertToast(
            this.$t('super_admin_js.integration_tasks.main_contact.toast.error.contacts_fetch'),
          );
        })
        .finally(() => {
          this.isFetchingHrisData = false;
        });
    },
    fetchOrganisationAdministrators() {
      this.isFetchingHrisData = true;

      httpClient
        .get(`/super_admin/api/users/administrators?organisation_id=${this.organisation.id}`)
        .then((response) => {
          response.data.data.forEach((user) => {
            this.$set(this.organisationAdministrators, user.id, {
                id: user.id,
                text: `${user.attributes.firstName} ${user.attributes.lastName}`,
              });
           });
        })
        .catch((error) => {
          this.makeAlertToast(
            this.$t('super_admin_js.integration_tasks.main_contact.toast.error.administrators_fetch'),
          );
        })
        .finally(() => {
          this.isFetchingHrisData = false;
        });
    },
    updateOrganisationMainContacts() {
      httpClient
        .patch(`/super_admin/api/organisations/${this.organisation.id}/contacts`, {
            contacts: {
              hris_integrations: Object.fromEntries(
                Object.entries(this.mainContacts).filter(([key, value]) => value !== null),
              ),
            },
        })
        .then((response) => {
          this.makeSuccessToast(
            this.$t('super_admin_js.integration_tasks.main_contact.toast.success.edit'),
          );
        })
        .catch((e) => {
          this.makeAlertToast(
            this.$t('super_admin_js.integration_tasks.main_contact.toast.error.edit'),
          );
        });
    },
    upsertMainContactHeaders() {
      const dateHeaderIdx = this.headers.findIndex((header) => header.name === 'execution_date');

      this.headers.splice(
        dateHeaderIdx + 1,
        0,
        {
            name: 'main_contact',
            title: this.$t('super_admin_js.integration_tasks.main_contact.label'),
            class: 'table__header-cell',
        },
      );
    },
    onSelectMainContact(option, integrationKey) {
      if (option.length === 0) {
        this.$set(this.mainContacts, integrationKey, null);
      } else {
        this.$set(this.mainContacts, integrationKey, option);
      }

      this.updateOrganisationMainContacts();
    },
    initializeMainContacts() {
      const hrisIntegrations = this.organisationContacts.hris_integrations;

      if (!hrisIntegrations) return;

      for (const [integrationKey, mainContacts] of Object.entries(hrisIntegrations)) {
        this.$set(this.mainContacts, integrationKey, mainContacts.map((user) => user.data.id));
      }
    },
    displayAdministrators(integrationKey) {
      const mainContacts = this.mainContacts[integrationKey] ?? [];

      if (
          mainContacts.length === 0
          || !this.organisationAdministrators[mainContacts[0]]
        ) return null;

      if (mainContacts.length === 1) return this.organisationAdministrators[mainContacts[0]].text;

      return `${this.organisationAdministrators[mainContacts[0]].text}, +${mainContacts.length - 1} more`;
    },
    buildMainContactsTooltip(integrationKey) {
      const mainContacts = this.mainContacts[integrationKey] ?? [];

      return mainContacts
        .map((userId) => this.organisationAdministrators[userId]?.text ?? '')
        .filter(Boolean)
        .join(', ');
      },
    },
};
</script>

<style lang="scss" scoped>
.sk-round-button--medium {
  height: 43px;
  width: 43px;
}

.sk-round-button-start {
  transform: scale(1, -1) rotate(140deg);
  padding: 4px;
}

.sk-round-button-start,
.sk-round-button-update {
  border: solid 1px $sk-gray-light-2;
  background: white;
}

.sk-round-button-start:hover,
.sk-round-button-update:hover {
  background: $sk-gray-light-4;
}

.integrations-jobs__table {
  margin-top: 40px;
  width: 100%;
  table-layout: fixed;

  td.table__cell {
    cursor: pointer;
    text-align: left;
    height: 60px;
    padding: 0 20px 0 0;
    border-bottom: 1px solid $sk-gray-light-4;
    vertical-align: middle;
  }

  ::v-deep .job-name__header {
    width: 300px;
  }

  ::v-deep .date__header {
    width: 135px;
  }

  ::v-deep .table__header-cell {
    font-family: $product-sans-font;
    font-size: 14px;
    font-weight: $fw-regular;
    color: $sk-gray-med-2;
    margin: 0;
  }

  ::v-deep .sk-datepicker__input {
    height: 36px;
    border: 1px solid $sk-gray-light-3;
    background: white;
  }
}
</style>

<style lang="scss">
// fixing SkDatePicker shifted cell style
.mx-table td {
  text-align: center;
}
</style>
