<template>
  <div>
    <h2 class="org-light__title">
      {{ organisation.name }}
    </h2>

    <ul class="org-light__shops-list">
      <li v-for="shop in shops" :key="shop.id" class="org-light__shop-item">
        <a :href="planningLink(shop)">
          {{ shop.name }}
        </a>
      </li>
    </ul>

    <h3 class="org-light__subtitle">
      {{ $t('super_admin_js.organisations.light.see_as') }}
    </h3>

    <div v-if="loaded">
      <div v-for="licenseType in licenseTypes" :key="licenseType.key">
        <h4 class="org-light__license-title">
          {{ licenseType.title }}
        </h4>
        <ul class="org-light__user-list">
          <li v-for="user in organisation[licenseType.key]" :key="user.id">
            <div class="flex-start">
              <a role="button" @click="impersonate(user)">
                {{ user.name }}
              </a>
              <div class="sa-resend-invit">
                <a role="button" @click="handleInviteClick(user)">
                  <i class="fa fa-envelope-o" />
                  {{ $t('super_admin_js.organisations.light.resend_invite') }}
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import httpClient from '@config/http_client';
import { buildLinkFor } from '@config';

import sendInviteMixin from '../../mixins/sendInviteMixin';
import impersonateUser, { getLoginErrorKey } from '../../utils/impersonate_user';

export default {
  mixins: [sendInviteMixin('singular')],
  data() {
    return {
      loaded: false,
      organisation: {},
      shops: [],
      organisationId: this.$route.params.id,
      licenseTypes: [
        {
          title: this.$t('super_admin_js.organisations.light.system_admins'),
          key: 'administrators',
        },
        {
          title: this.$t('super_admin_js.organisations.light.advanced_planners'),
          key: 'advanced_planner',
        },
        {
          title: this.$t('super_admin_js.organisations.light.advanced_masters'),
          key: 'advanced_master',
        },
      ],
    };
  },
  created() {
    this.fetchOrganisation();
    this.fetchShops();
  },
  methods: {
    handleInviteClick(user) {
      // this.currentModalUser = user;
      this.handleSendInviteClick();
    },
    impersonate(user) {
      impersonateUser(user.id)
        .catch((error) => {
          this.makeAlertToast(this.$t(getLoginErrorKey(error)));
        });
    },
    planningLink(shop) {
      return buildLinkFor(`/week_plannings/${shop.id}`);
    },
    fetchOrganisation() {
      httpClient
        .get(`/super_admin/api/organisations/${this.organisationId}`)
        .then((response) => {
          this.loaded = true;
          this.organisation = response.data.organisation;
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.$router.push('/404');
            return;
          }
          this.makeAlertToast(
            this.$t('super_admin_js.organisation.errors.loading'),
          );
        });
    },
    fetchShops() {
      const params = { list_only: true };
      httpClient
        .get(`/super_admin/api/organisations/${this.organisationId}/shops`, { params })
        .then((response) => {
          this.shops = response.data;
        })
        .catch((e) => {
          if (e.response.status === 404) {
            this.$router.push('/404');
            return;
          }
          this.makeAlertToast(
            this.$t('super_admin_js.organisation.errors.loading'),
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.org-light__title {
  font-size: 41px;
  margin-top: 0;
  margin-bottom: 11px;
}

.org-light__shops-list {
  list-style: disc outside none;
}

.org-light__shop-item {
  font-size: 16px;
}

.org-light__subtitle {
  font-size: 34px;
  margin-top: 22px;
  margin-bottom: 11px;
}

.org-light__license-title {
  font-size: 28px;
  margin-top: 22px;
  margin-bottom: 11px;
}

.org-light__user-list {
  list-style: disc outside none;
  font-size: 16px;
}

.sa-resend-invit {
  margin-left: 2em;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>
